import React, { useState } from "react";
import "./PartnersProvidersManager.css";

const PartnerProviderManager = () => {
  const [partnerId, setPartnerId] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [providers, setProviders] = useState([]);
  const [globalError, setGlobalError] = useState("");
  const [editingProvider, setEditingProvider] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [providerErrors, setProviderErrors] = useState({});
  const [copiedLink, setCopiedLink] = useState("");

  const callLinkGeneratorWorker = async (endpoint, method, body) => {
    console.log("Entering callLinkGeneratorWorker function");
    console.log(
      "LINK_GENERATOR_WORKER available:",
      typeof LINK_GENERATOR_WORKER !== "undefined"
    );
    console.log(
      "VITE_LINK_GENERATOR_WORKER_URL:",
      import.meta.env.VITE_LINK_GENERATOR_WORKER_URL
    );

    let response;
    const headers = {
      "Content-Type": "application/json",
    };

    if (typeof LINK_GENERATOR_WORKER !== "undefined") {
      console.log("Using Cloudflare Worker binding for Link Generator");
      try {
        response = await LINK_GENERATOR_WORKER.fetch(endpoint, {
          method,
          body: JSON.stringify(body),
          headers,
        });
        console.log("Successfully used LINK_GENERATOR_WORKER binding");
      } catch (error) {
        console.error("Error using LINK_GENERATOR_WORKER binding:", error);
      }
    } else {
      const LINK_GENERATOR_WORKER_URL = import.meta.env
        .VITE_LINK_GENERATOR_WORKER_URL;
      console.log(
        "Using VITE_LINK_GENERATOR_WORKER_URL:",
        LINK_GENERATOR_WORKER_URL
      );
      if (!LINK_GENERATOR_WORKER_URL) {
        console.error(
          "LINK_GENERATOR_WORKER_URL is not defined in environment variables"
        );
        throw new Error(
          "LINK_GENERATOR_WORKER_URL is not defined in environment variables"
        );
      }
      try {
        response = await fetch(`${LINK_GENERATOR_WORKER_URL}${endpoint}`, {
          method,
          body: JSON.stringify(body),
          headers,
        });
        console.log("Successfully used VITE_LINK_GENERATOR_WORKER_URL");
      } catch (error) {
        console.error("Error using fetch API:", error);
      }
    }

    console.log("Exiting callLinkGeneratorWorker function");

    if (!response) {
      console.error("No response received from LINK_GENERATOR_WORKER");
      throw new Error("Failed to get response from LINK_GENERATOR_WORKER");
    }

    return response;
  };

  const fetchProviders = async () => {
    console.log("Fetching providers for partnerId:", partnerId);

    if (!partnerId) {
      console.log("No partnerId provided, showing error");
      setGlobalError("Please enter a Partner ID.");
      return;
    }

    setIsLoading(true);
    setGlobalError("");
    setProviderErrors({});

    try {
      console.log("Making API request to:", `/api/providers/${partnerId}`);
      const response = await callLinkGeneratorWorker(
        `/api/providers/${partnerId}`,
        "GET"
      );
      console.log("API response status:", response.status);

      if (!response.ok) {
        throw new Error(`Failed to fetch providers: ${response.status}`);
      }

      const data = await response.json();
      console.log("Received data:", data);
      setPartnerName(data.partnerName);

      console.log("Mapping providers data...");
      const mappedProviders = data.providers.map((provider) => {
        const mapped = {
          ...provider,
          marketplaceLink: parseLink(provider.marketplaceLink),
          customUsername: provider.personalUsername,
          customLink: provider.personalLink,
        };
        console.log("Mapped provider:", mapped);
        return mapped;
      });

      console.log("Setting providers state with mapped data");
      setProviders(mappedProviders);
    } catch (error) {
      console.error("Error in fetchProviders:", error);
      setGlobalError("Failed to fetch providers. Please try again.");
    } finally {
      console.log("Fetch operation complete, setting isLoading to false");
      setIsLoading(false);
    }
  };

  const parseLink = (linkData) => {
    try {
      const parsed = JSON.parse(linkData);
      return parsed.link || linkData;
    } catch (e) {
      return linkData;
    }
  };

  const handleCreateOrUpdateCustomLink = async (provider, username) => {
    setIsLoading(true);
    setProviderErrors((prev) => ({ ...prev, [provider.id]: "" }));

    try {
      const response = await callLinkGeneratorWorker(
        "/api/personal-link",
        "POST",
        {
          username,
          providerId: provider.id,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to create/update custom link"
        );
      }

      const { link } = await response.json();

      setProviders((prevProviders) =>
        prevProviders.map((p) =>
          p.id === provider.id
            ? { ...p, customLink: link, customUsername: username }
            : p
        )
      );

      setEditingProvider(null);

      setCopiedLink(`Custom link for ${provider.name} updated successfully`);
      setTimeout(() => setCopiedLink(""), 3000);
    } catch (error) {
      console.error("Error creating/updating custom link:", error);
      setProviderErrors((prev) => ({
        ...prev,
        [provider.id]:
          error.message ||
          "Failed to create/update custom link. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedLink(text);
      setTimeout(() => setCopiedLink(""), 20000);
    });
  };

  return (
    <div className="partner-manager-container">
      <div className="partner-manager-header">
        <h2 className="partner-manager-title">Partner Provider Manager</h2>
      </div>

      <div className="partner-manager-content">
        <div className="input-group">
          <label className="input-label" htmlFor="partnerId">
            Partner ID
          </label>
          <div className="input-wrapper">
            <input
              id="partnerId"
              type="text"
              value={partnerId}
              onChange={(e) => setPartnerId(e.target.value)}
              className="partner-id-input"
              placeholder="Enter Partner ID"
              disabled={isLoading}
            />
            <button
              onClick={fetchProviders}
              className="fetch-button"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Fetch"}
            </button>
          </div>
        </div>

        {globalError && (
          <div className="error-message">
            <p className="error-title">Error</p>
            <p className="error-content">{globalError}</p>
          </div>
        )}

        {partnerName && (
          <div className="success-message">
            <p className="success-title">Partner Name:</p>
            <p className="success-content">{partnerName}</p>
          </div>
        )}

        {providers.length > 0 && (
          <div className="provider-list">
            <h3 className="provider-list-title">Provider Links</h3>
            <ul className="provider-list-items">
              {providers.map((provider) => (
                <li key={provider.id} className="provider-item">
                  <div className="provider-header">
                    <h4 className="provider-name">{provider.name}</h4>
                    <p className="provider-id">ID: {provider.id}</p>
                  </div>
                  <div className="provider-links">
                    <div className="link-group">
                      <p className="link-label">Marketplace Link:</p>
                      <div className="link-with-copy">
                        <a
                          href={provider.marketplaceLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="provider-link"
                        >
                          {provider.marketplaceLink}
                        </a>
                        <button
                          onClick={() =>
                            copyToClipboard(provider.marketplaceLink)
                          }
                          className="copy-button"
                          title="Copy to clipboard"
                        >
                          📋
                        </button>
                        {copiedLink === provider.marketplaceLink && (
                          <span className="copied-notification">Copied!</span>
                        )}
                      </div>
                    </div>

                    <div className="link-group">
                      <p className="link-label">Custom Link:</p>
                      <div className="link-with-copy">
                        <a
                          href={provider.customLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="provider-link"
                        >
                          {provider.customLink}
                        </a>
                        <button
                          onClick={() => copyToClipboard(provider.customLink)}
                          className="copy-button"
                          title="Copy to clipboard"
                        >
                          📋
                        </button>
                        {copiedLink === provider.customLink && (
                          <span className="copied-notification">Copied!</span>
                        )}
                      </div>
                      <p className="custom-username">
                        Username: {provider.customUsername}
                      </p>
                    </div>
                  </div>
                  {providerErrors[provider.id] && (
                    <p className="provider-error">
                      {providerErrors[provider.id]}
                    </p>
                  )}
                  {editingProvider?.id === provider.id ? (
                    <div className="edit-custom-link-form">
                      <input
                        type="text"
                        defaultValue={provider.customUsername || ""}
                        placeholder="Enter custom username"
                        className="custom-username-input"
                        disabled={isLoading}
                      />
                      <div className="button-group">
                        <button
                          onClick={(e) => {
                            const input = e.target
                              .closest(".edit-custom-link-form")
                              .querySelector("input");
                            handleCreateOrUpdateCustomLink(
                              provider,
                              input.value
                            );
                          }}
                          className="save-custom-link-button"
                          disabled={isLoading}
                        >
                          {isLoading ? "Saving..." : "Update"}
                        </button>
                        <button
                          onClick={() => setEditingProvider(null)}
                          className="cancel-button"
                          disabled={isLoading}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => setEditingProvider(provider)}
                      className="edit-custom-link-button"
                      disabled={isLoading}
                    >
                      Edit Custom Link
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerProviderManager;
